import React, { useState } from 'react';

import '../../assets/css/balance.css';
import balance from '../../assets/js/balance.js';

const Balance = () => {
	const [formula, setFormula] = useState('');
	const [balanced, setBalanced] = useState('');
	const [alert, setAlert] = useState('');

	const handleBalance = (e) => {
		e.preventDefault();

		try {
			const balanced = balance(formula);
			setBalanced(balanced.outerHTML);
			setAlert('');
		} catch {
			setBalanced('');
			setAlert('Внесовте невалидна равенка');
		}
	};

	return (
		<div>
			<h3>Израмнување хемиски равенки</h3>

			<form className='balance-form' onSubmit={handleBalance}>
				<label htmlFor='formula'>Внеси равенка:</label>
				<input
					type='text'
					name='formula'
					id='formula'
					onChange={(e) => setFormula(e.target.value)}
					placeholder='H2 + O2 = H2O'
				/>
				<br />
				<button type='submit'>Израмни</button>
			</form>

			{alert ? <p class='alert alert-error'>{alert}</p> : ''}
			{balanced ? (
				<>
					<h4 className='m-0 mt-2'>Резултат:</h4>
					<span
						className='balancedResult'
						dangerouslySetInnerHTML={{ __html: balanced }}
					></span>
				</>
			) : (
				''
			)}
		</div>
	);
};

export default Balance;

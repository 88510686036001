import React, { useState } from 'react';

import '../../assets/css/element_data.css';

String.prototype.toCammelCase = function () {
	if (!this.length) return this;
	return this[0].toUpperCase() + this.substr(1).toLowerCase();
};

const DataElement = ({ label, value, newline }) => {
	if (value) {
		return (
			<p className='data-element'>
				<b>{label}: </b>
				{newline === true ? <br /> : null}
				{value}
			</p>
		);
	}

	return null;
};

const ElementData = ({ element: e, ...otherProps }) => {
	return (
		<section {...otherProps}>
			<header className='element-header'>
				<h4 className='element-name'>
					{e.name.toCammelCase()} - {e.symbol}
				</h4>
				<a href={e.source} target='_blank' className='element-wiki'>
					<i class='fab fa-wikipedia-w'></i>
				</a>
			</header>
			<DataElement label='Реден број' value={e.number} />
			<DataElement label='Маса' value={e.atomic_mass} />
			<DataElement label='Категорија' value={e.category.toCammelCase()} />
			<DataElement
				label='Електронска обвивка'
				value={`(${e.shells.join(', ')})`}
				newline
			/>
			<DataElement
				label='Електронска конф.'
				value={e.electron_configuration}
				newline
			/>
			<DataElement
				label='Изглед'
				value={e.appearance ? e.appearance.toCammelCase() : null}
			/>
			<DataElement label='Т на вриење' value={e.boil} />
			<DataElement label='Т на топење' value={e.melt} />
			<DataElement label='Откриено од' value={e.discovered_by} />
		</section>
	);
};

const Alert = ({ message: msg, ...otherProps }) => {
	const [visible, setVisible] = useState(true);
	const [fade, setFade] = useState(false);

	setTimeout(() => setVisible(false), 2000);

	return (
		<>
			{visible ? (
				<p className='alert alert-error' {...otherProps}>
					{msg}
				</p>
			) : null}
		</>
	);
};

const ElementInfo = () => {
	const [currElement, setCurrElement] = useState('');
	const [elementsData, setElementsData] = useState([]);
	const [alerts, setAlerts] = useState([]);

	const handleData = async (e) => {
		e.preventDefault();

		try {
			const res = await fetch(`/elements/${currElement}`);

			let currElemData;
			try {
				currElemData = await res.json();
			} catch (error) {
				throw Error('Внесовте непостоечки елемент');
			}

			setElementsData([currElemData, ...elementsData]);
		} catch (error) {
			console.error(error);
			setAlerts([error.toString(), ...alerts]);
		}
	};

	return (
		<>
			<h3>Брзи податоци за елементи</h3>

			<form className='element-form' onSubmit={handleData}>
				<label htmlFor='element'>Внеси елемент:</label>
				<input
					type='text'
					name='element'
					id='element'
					onChange={(e) => setCurrElement(e.target.value.toLowerCase())}
					placeholder='H'
				/>
				<br />
				<button type='submit'>Пронајди</button>
			</form>

			{alerts.length
				? alerts.map((msg, i) => (
						<Alert key={i} className='alert alert-error' message={msg} />
				  ))
				: null}

			{elementsData ? (
				<div className='all-elements'>
					{elementsData.map((el, i) => (
						<ElementData key={i} className='element-info' element={el} />
					))}
				</div>
			) : null}
		</>
	);
};

export default ElementInfo;

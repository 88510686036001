import React from 'react';
import { Link } from 'react-router-dom';

import '../../assets/css/navigation.css';

const Navigation = () => {
	return (
		<header className='navigation'>
			<div className='nav-links'>
				<Link to='/' className='nav-link'>
					🏡
				</Link>
				<Link to='/info' className='nav-link'>
					Најди Елемент
				</Link>
				<Link to='/balance' className='nav-link'>
					Израмни
				</Link>
			</div>
		</header>
	);
};

export default Navigation;

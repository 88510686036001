import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
	return (
		<>
			<h3>Добредојдовте!</h3>
			<p>
				Овде може да пронајдете најразлични податоци за хемиските елементи, но и
				да си ги проверите вашите решенија на задачите за израмнување равенки.
			</p>
			<p>
				<i
					class='fas fa-question-circle fa-1x'
					style={{ fontSize: '2rem', color: '#33f', marginRight: '10px' }}
				></i>
				За навигација користете ги трите линкови на горниот дел од страната (
				<Link to='/'>🏡</Link>, <Link to='/info'>Најди Елемент</Link>,{' '}
				<Link to='/balance'>Израмни</Link>)
			</p>
			<p>
				Изработено од: <b>Влатко Стојкоски</b>
			</p>
		</>
	);
};

export default Home;

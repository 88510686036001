import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import './assets/css/style.css';

import Home from './components/pages/Home';
import Balance from './components/pages/Balance';
import ElementInfo from './components/pages/ElementInfo';
import Navigation from './components/layout/Navigation';

const App = () => {
	return (
		<Router>
			<Navigation />
			<main>
				<Route exact path='/'>
					<Home />
				</Route>
				<Route exact path='/balance'>
					<Balance />
				</Route>
				<Route exact path='/info'>
					<ElementInfo />
				</Route>
			</main>
		</Router>
	);
};

export default App;
